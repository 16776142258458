export enum FeatureCode {
  WEB_BUYBACK_ENTER_ADDRESS = 'web-buyback-enter-address',
  WEB_BUYBACK_STAFF_ORDER = 'web-buyback-staff-order',
  WEB_CHECKOUT_BILLING = 'web-checkout-billing',
  WEB_CHECKOUT_COLLECTION_POINTS = 'web-checkout-collection-points',
  WEB_CHECKOUT_SHIPPING = 'web-checkout-shipping',
  WEB_COMPANY_INFO_PROFILE = 'web-company-info-profile',
  WEB_CUSTOMER_UPDATE_ADDRESS = 'web-customer-update-address',
  WEB_MERCHANT_REGISTER_FORM = 'web-merchant-register-form',
  WEB_MERCHANT_UPDATE_ADDRESS = 'web-merchant-update-address',
  WEB_MERCHANT_OPTION_ADDRESS = 'web-merchant-option-address',
  WEB_SHIPPING_LABEL_CREATION = 'web-shipping-label-creation',
  WEB_SHIPPING_ADDRESS_FIELD_VALIDATION_PLAYGROUND = 'web-shipping-address-field-validation-playground',
}

/**
 * Since we pay Algolia Places per number of requests being made, let's try to
 * avoid unnecessary ones. This values sets the minimum number of characters
 * a user will need to type to trigger a search request.
 */
export const ADDRESS_AUTOCOMPLETE_MINIMUM_LENGTH = 5

/**
 * This values sets the Algolia Places debounce delay for user input (in ms).
 * It will wait this amount of time before sending a search request, so let's
 * try to keep it as low as possible to minimize input lag, but high enough to
 * avoid unnecessary requests.
 */
export const ADDRESS_AUTOCOMPLETE_DEBOUNCE_DELAY = 100

export const ADDRESS_AUTOCOMPLETE_MAX_OPTIONS = 5
